var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "cyui-layout-wrapper"
  }, [_c('cyui-layout', {
    scopedSlots: _vm._u([{
      key: "header",
      fn: function () {
        return [_vm.isCustomHeader ? _c('cyui-header', {
          attrs: {
            "title": _vm.headerTitle
          },
          on: {
            "go-back": _vm.goBack,
            "on-close": _vm.handleClosePage
          },
          scopedSlots: _vm._u([{
            key: "headerRight",
            fn: function () {
              return [_c('div')];
            },
            proxy: true
          }], null, false, 378232108)
        }) : _vm._e()];
      },
      proxy: true
    }, {
      key: "default",
      fn: function () {
        return [_c('div', {
          staticClass: "cyui-detail-header"
        }, [`${_vm.status}` === '1' || `${_vm.status}` === '4' || `${_vm.status}` === '2' ? [_c('div', {
          staticClass: "cyui-detail-header-body",
          staticStyle: {
            "background-color": "#75BEFC"
          }
        }, [_c('div', {
          staticClass: "cyui-detail-header-status"
        }, [_c('div', {
          staticClass: "cyui-detail-header-status-icon"
        }, [_c('svg-icon', {
          attrs: {
            "icon-class": "sign_contract_3"
          }
        })], 1), `${_vm.status}` === '1' ? _c('div', [_vm._v("等待管家确认")]) : `${_vm.status}` === '2' ? _c('div', [_vm._v("等待管家确认续租")]) : `${_vm.status}` === '4' ? _c('div', [_vm._v("等待客户签署")]) : _vm._e()])])] : `${_vm.status}` === '5' || `${_vm.status}` === '9' ? [_c('div', {
          staticClass: "cyui-detail-header-body",
          staticStyle: {
            "background-color": "#82DDC2"
          }
        }, [_c('div', {
          staticClass: "cyui-detail-header-status"
        }, [_c('div', {
          staticClass: "cyui-detail-header-status-icon"
        }, [_c('svg-icon', {
          attrs: {
            "icon-class": "sign_contract_2"
          }
        })], 1), `${_vm.status}` === '5' ? _c('div', [_vm._v("等待客户支付")]) : `${_vm.status}` === '9' ? _c('div', [_vm._v("签约已完成")]) : _vm._e()])])] : `${_vm.status}` === '3' || `${_vm.status}` === '6' || `${_vm.status}` === '7' || `${_vm.status}` === '8' || `${_vm.status}` === '10' ? [_c('div', {
          staticClass: "cyui-detail-header-body",
          staticStyle: {
            "background-color": "#C3C3C3"
          }
        }, [_c('div', {
          staticClass: "cyui-detail-header-status"
        }, [_c('div', {
          staticClass: "cyui-detail-header-status-icon"
        }, [_c('svg-icon', {
          attrs: {
            "icon-class": "sign_contract_1"
          }
        })], 1), `${_vm.status}` === '10' ? _c('div', [_vm._v("合同已到期")]) : _c('div', [_vm._v("签约已取消")])]), _c('div', {
          staticClass: "cyui-detail-header-msg"
        }, [_c('div', [_vm._v(_vm._s(_vm.detailInfo.failureMsg || '-'))])])])] : _vm._e()], 2), _c('div', {
          staticClass: "cyui-section"
        }, [_c('div', {
          staticClass: "cyui-card cyui-section-item"
        }, [_c('div', {
          staticClass: "cyui-card-body",
          staticStyle: {
            "padding": "0"
          }
        }, [_c('div', {
          staticClass: "cyui-section-client"
        }, [_c('div', {
          staticClass: "cyui-list cyui-commodity-info"
        }, [_c('div', {
          staticClass: "cyui-list-body"
        }, [_c('div', {
          staticClass: "cyui-list-item cyui-list-multiple"
        }, [`${_vm.status}` === '1' ? _c('div', {
          staticClass: "cyui-list-line"
        }, [_c('div', {
          staticClass: "cyui-list-thumb"
        }, [_c('img', {
          attrs: {
            "src": _vm.roomInfo.coverImg,
            "alt": ""
          }
        })]), _c('div', {
          staticClass: "cyui-list-content"
        }, [_c('div', {
          staticClass: "cyui-commodity-title"
        }, [_vm._v(_vm._s(_vm.roomInfo.projectName || '-') + " " + _vm._s(_vm.roomInfo.roomTypeName || '-') + " ")]), _c('div', [_vm._v(_vm._s(_vm.roomInfo.area || '-') + "㎡ " + _vm._s(_vm.roomInfo.houseType || '-'))]), _c('div', [_vm._v(_vm._s(_vm.roomInfo.roomArea || '-') + "·" + _vm._s(_vm.roomInfo.sheet || '-'))]), _c('div', {
          staticClass: "cyui-commodity-price"
        }, [_c('em', [_vm._v(_vm._s(_vm.roomInfo.monthRentPrice || '-'))]), _vm._v(" 元/月")])])]) : _c('div', {
          staticClass: "cyui-list-title"
        }, [_c('span', [_vm._v(" " + _vm._s(_vm.detailInfo.baseRoomTypeEnt ? _vm.detailInfo.baseRoomTypeEnt.projectName : '') + " ")]), _c('span', [_vm._v(" " + _vm._s(_vm.detailInfo.contractVO ? '房号：' + _vm.detailInfo.contractVO.roomNo : '') + " ")])])])])])])])]), _c('div', {
          staticClass: "cyui-card cyui-section-item"
        }, [_c('div', {
          staticClass: "cyui-card-header"
        }, [_c('div', {
          staticClass: "cyui-card-header-line"
        }, [_c('div', {
          staticClass: "cyui-card-header-content"
        }, [_vm._v(" 签约主体 ")])])]), _c('div', {
          staticClass: "cyui-card-body",
          staticStyle: {
            "padding": "0"
          }
        }, [_c('div', {
          staticClass: "cyui-section-client"
        }, [_c('div', {
          staticClass: "cyui-staff-info"
        }, [_c('div', {
          staticClass: "cyui-staff-info-body"
        }, [_c('div', {
          staticClass: "cyui-list"
        }, [_c('div', {
          staticClass: "cyui-list-body"
        }, [_c('div', {
          staticClass: "cyui-list-item"
        }, [_c('div', {
          staticClass: "cyui-list-line"
        }, [_c('div', {
          staticClass: "cyui-list-label cyui-list-label-5"
        }, [_vm._v("姓名：")]), _c('div', {
          staticClass: "cyui-list-content"
        }, [_vm._v(_vm._s(_vm.detailInfo.stewardName || '-'))])])]), _c('div', {
          staticClass: "cyui-list-item"
        }, [_c('div', {
          staticClass: "cyui-list-line"
        }, [_c('div', {
          staticClass: "cyui-list-label cyui-list-label-5"
        }, [_vm._v("手机号：")]), _c('div', {
          staticClass: "cyui-list-content"
        }, [_vm._v(_vm._s(_vm.detailInfo.stewardMobile || '-'))])])])])])])])])])]), `${_vm.status}` === '1' || `${_vm.status}` === '2' ? [_vm.detailInfo.checkState !== 0 ? _c('div', {
          staticClass: "cyui-card cyui-section-item"
        }, [_c('div', {
          staticClass: "cyui-card-header"
        }, [_c('div', {
          staticClass: "cyui-card-header-line"
        }, [_c('div', {
          staticClass: "cyui-card-header-content"
        }, [_vm._v(" 审核信息 ")])])]), _c('div', {
          staticClass: "cyui-card-body",
          staticStyle: {
            "padding": "0"
          }
        }, [_c('div', {
          staticClass: "cyui-section-client"
        }, [_c('div', {
          staticClass: "cyui-rent"
        }, [_c('div', {
          staticClass: "cyui-list cyui-rent-content"
        }, [_c('div', {
          staticClass: "cyui-list-body"
        }, [_c('div', {
          staticClass: "cyui-list-item cyui-rent-content-item"
        }, [_c('div', {
          staticClass: "cyui-list-line"
        }, [_c('div', {
          staticClass: "cyui-list-content"
        }, [_c('div', {
          staticClass: "cyui-rent-content-title"
        }, [_vm._v(" 审核状态 ")])]), _c('div', {
          staticClass: "cyui-list-extra"
        }, [_vm.detailInfo.checkState === 1 ? _c('div', {
          staticClass: "cyui-rent-content-value"
        }, [_vm._v(" 审核中 ")]) : _vm.detailInfo.checkState === 2 ? _c('div', {
          staticClass: "cyui-rent-content-value"
        }, [_vm._v(" 审核通过 ")]) : _vm.detailInfo.checkState === 3 ? _c('div', {
          staticClass: "cyui-rent-content-value"
        }, [_vm._v(" 审核未通过 ")]) : _vm._e()])])]), _c('div', {
          staticClass: "cyui-list-item cyui-rent-content-item"
        }, [_c('div', {
          staticClass: "cyui-list-line"
        }, [_c('div', {
          staticClass: "cyui-list-content"
        }, [_c('div', {
          staticClass: "cyui-rent-content-title"
        }, [_vm._v(" 审核类型 ")])]), _c('div', {
          staticClass: "cyui-list-extra"
        }, [_vm.detailInfo.checkType === 1 ? _c('div', {
          staticClass: "cyui-rent-content-value"
        }, [_vm._v(" 新签破底价 ")]) : _vm._e(), _vm.detailInfo.checkType === 2 ? _c('div', {
          staticClass: "cyui-rent-content-value"
        }, [_vm._v(" 续签价格过低 ")]) : _vm._e()])])]), _c('div', {
          staticClass: "cyui-list-item cyui-rent-content-item"
        }, [_c('div', {
          staticClass: "cyui-list-line"
        }, [_c('div', {
          staticClass: "cyui-list-content"
        }, [_c('div', {
          staticClass: "cyui-rent-content-title"
        }, [_vm._v(" 提交审核时间 ")])]), _c('div', {
          staticClass: "cyui-list-extra"
        }, [_c('div', {
          staticClass: "cyui-rent-content-value"
        }, [_vm._v(" " + _vm._s(_vm.detailInfo.checkSubmitTime) + " ")])])])]), _vm.detailInfo.checkType === 1 ? _c('div', {
          staticClass: "cyui-list-item cyui-rent-content-item"
        }, [_c('div', {
          staticClass: "cyui-list-line"
        }, [_c('div', {
          staticClass: "cyui-list-content"
        }, [_c('div', {
          staticClass: "cyui-rent-content-title"
        }, [_vm._v(" 审核底价价格 ")])]), _c('div', {
          staticClass: "cyui-list-extra"
        }, [_c('div', {
          staticClass: "cyui-rent-content-value"
        }, [_vm._v(" " + _vm._s(_vm.detailInfo.checkPriceMin) + " ")])])])]) : _vm._e(), _vm.detailInfo.checkType === 2 ? _c('div', {
          staticClass: "cyui-list-item cyui-rent-content-item"
        }, [_c('div', {
          staticClass: "cyui-list-line"
        }, [_c('div', {
          staticClass: "cyui-list-content"
        }, [_c('div', {
          staticClass: "cyui-rent-content-title"
        }, [_vm._v(" 审核续签价格 ")])]), _c('div', {
          staticClass: "cyui-list-extra"
        }, [_c('div', {
          staticClass: "cyui-rent-content-value"
        }, [_vm._v(" " + _vm._s(_vm.detailInfo.checkPriceRenew) + " ")])])])]) : _vm._e()])])])])])]) : _vm._e(), _c('div', {
          staticClass: "cyui-card cyui-section-item"
        }, [_c('div', {
          staticClass: "cyui-card-header"
        }, [_c('div', {
          staticClass: "cyui-card-header-line"
        }, [_c('div', {
          staticClass: "cyui-card-header-content"
        }, [_vm._v(" 租赁信息 ")])])]), _c('div', {
          staticClass: "cyui-card-body",
          staticStyle: {
            "padding": "0"
          }
        }, [_c('div', {
          staticClass: "cyui-section-client"
        }, [_c('div', {
          staticClass: "cyui-rent"
        }, [_c('div', {
          staticClass: "cyui-list cyui-rent-content"
        }, [_c('div', {
          staticClass: "cyui-list-body"
        }, [`${_vm.status}` === '1' ? _c('div', {
          staticClass: "cyui-list-item cyui-rent-content-item",
          on: {
            "click": function ($event) {
              _vm.batchInfo.show = true;
            }
          }
        }, [_c('div', {
          staticClass: "cyui-list-line"
        }, [_c('div', {
          staticClass: "cyui-list-content"
        }, [_c('div', {
          staticClass: "cyui-rent-content-title"
        }, [_vm._v(" 选择批次 ")])]), _c('div', {
          staticClass: "cyui-list-extra"
        }, [_c('div', {
          staticClass: "cyui-rent-content-value"
        }, [_vm._v(" " + _vm._s(_vm.confirmParamsInfo.batchName) + " ")])]), _c('div', {
          staticClass: "cyui-list-arrow cyui-list-arrow-right"
        })])]) : _vm._e(), _c('div', {
          staticClass: "cyui-list-item cyui-rent-content-item",
          on: {
            "click": function ($event) {
              `${_vm.status}` === '1' && (_vm.confirmCalendarInfo.show = true);
            }
          }
        }, [_c('div', {
          staticClass: "cyui-list-line"
        }, [_c('div', {
          staticClass: "cyui-list-content"
        }, [_c('div', {
          staticClass: "cyui-rent-content-title"
        }, [_vm._v(" 租赁开始日期 ")])]), _c('div', {
          staticClass: "cyui-list-extra"
        }, [_c('div', {
          staticClass: "cyui-rent-content-value"
        }, [_vm._v(" " + _vm._s(_vm._f("formatParamsDate")(_vm.confirmParamsInfo.startDate)) + " ")])]), `${_vm.status}` === '1' ? _c('div', {
          staticClass: "cyui-list-arrow cyui-list-arrow-right"
        }) : _vm._e()])]), _c('div', {
          staticClass: "cyui-list-item cyui-rent-content-item"
        }, [_c('div', {
          staticClass: "cyui-list-line"
        }, [_c('div', {
          staticClass: "cyui-list-content"
        }, [_c('div', {
          staticClass: "cyui-rent-content-title"
        }, [_vm._v(" 整月数量 ")])]), _c('div', {
          staticClass: "cyui-list-extra"
        }, [_c('van-field', {
          attrs: {
            "clearable": "",
            "input-align": "right",
            "placeholder": "请输入整月数量",
            "type": "digit"
          },
          on: {
            "input": _vm.handleInputMonthNumber
          },
          scopedSlots: _vm._u([{
            key: "right-icon",
            fn: function () {
              return [_c('div', [_vm._v("月")])];
            },
            proxy: true
          }], null, false, 2784675455),
          model: {
            value: _vm.confirmParamsInfo.monthNum,
            callback: function ($$v) {
              _vm.$set(_vm.confirmParamsInfo, "monthNum", $$v);
            },
            expression: "confirmParamsInfo.monthNum"
          }
        })], 1)])]), (_vm.confirmParamsInfo.monthNum || 0) == _vm.confirmParamsInfo.moreMonth ? _c('div', {
          staticClass: "cyui-list-item cyui-rent-content-item"
        }, [_c('div', {
          staticClass: "cyui-list-line"
        }, [_c('div', {
          staticClass: "cyui-list-content"
        }, [_c('div', {
          staticClass: "cyui-rent-content-title"
        }, [_vm._v(" 是否赠送" + _vm._s(_vm.confirmParamsInfo.otherDays) + "天 "), _c('div', {
          staticClass: "cyui-describe"
        }, [_vm._v(_vm._s(_vm.confirmParamsInfo.salesDesc))])])]), _c('div', {
          staticClass: "cyui-list-extra",
          staticStyle: {
            "flex-basis": "auto"
          }
        }, [_c('van-radio-group', {
          attrs: {
            "direction": "horizontal",
            "icon-size": "18px"
          },
          on: {
            "change": _vm.handleRentOtherDaysChange
          },
          model: {
            value: _vm.isRentOtherDays,
            callback: function ($$v) {
              _vm.isRentOtherDays = $$v;
            },
            expression: "isRentOtherDays"
          }
        }, [_c('van-radio', {
          attrs: {
            "name": _vm.confirmParamsInfo.otherDays
          }
        }, [_vm._v("是")]), _c('van-radio', {
          attrs: {
            "name": 0
          }
        }, [_vm._v("否")])], 1)], 1)])]) : _vm._e(), _c('div', {
          staticClass: "cyui-list-item cyui-rent-content-item"
        }, [_c('div', {
          staticClass: "cyui-list-line"
        }, [_c('div', {
          staticClass: "cyui-list-content"
        }, [_c('div', {
          staticClass: "cyui-rent-content-title"
        }, [_vm._v(" 租赁结束日期 ")])]), _c('div', {
          staticClass: "cyui-list-extra"
        }, [_c('div', {
          staticClass: "cyui-rent-content-value"
        }, [_vm._v(" " + _vm._s(_vm._f("formatParamsDate")(_vm.confirmParamsInfo.endDate)) + " ")])]), _c('div', {
          staticClass: "cyui-list-arrow cyui-list-arrow-right"
        })])]), `${_vm.status}` === '1' ? _c('div', {
          staticClass: "cyui-card cyui-section-item"
        }, [_c('div', {
          staticClass: "cyui-card-header",
          staticStyle: {
            "padding": "0"
          }
        }, [_c('div', {
          staticClass: "cyui-card-header-line"
        }, [_c('div', {
          staticClass: "cyui-card-header-content",
          staticStyle: {
            "color": "#999999"
          }
        }, [_vm._v(" 房源选择 ")])])]), _c('div', {
          staticClass: "cyui-card-body",
          staticStyle: {
            "padding": "0"
          }
        }, [_c('div', {
          staticClass: "cyui-section-client"
        }, [_vm.houseListData.length ? _c('div', {
          staticClass: "cyui-house-list"
        }, [_c('van-cell-group', _vm._l(_vm.houseListData, function (item) {
          return _c('van-cell', {
            key: `house${item.houseCode}`,
            on: {
              "click": function ($event) {
                return _vm.handleSelectHouse(item);
              }
            },
            scopedSlots: _vm._u([{
              key: "icon",
              fn: function () {
                return [_c('div', {
                  staticClass: "cyui-house-list-thumb"
                }, [_c('svg-icon', {
                  attrs: {
                    "icon-class": item.isSelected ? 'radio_checked' : 'radio'
                  }
                })], 1)];
              },
              proxy: true
            }, {
              key: "title",
              fn: function () {
                return [_c('div', {
                  staticClass: "cyui-flexbox cyui-house-item"
                }, [_c('div', {
                  staticClass: "cyui-flexbox-item"
                }, [_c('div', {
                  staticClass: "cyui-house-name"
                }, [_vm._v(_vm._s(item.projectName) + " " + _vm._s(item.roomNo))])])])];
              },
              proxy: true
            }], null, true)
          });
        }), 1)], 1) : _c('div', {
          staticClass: "no-data"
        }, [_vm._v("暂无数据")])])])]) : _vm._e(), _c('div', {
          staticClass: "cyui-list-item cyui-rent-content-item",
          on: {
            "click": _vm.handleClinchDealSourceInfoShow
          }
        }, [_c('div', {
          staticClass: "cyui-list-line"
        }, [_c('div', {
          staticClass: "cyui-list-content"
        }, [_c('div', {
          staticClass: "cyui-rent-content-title"
        }, [_vm._v(" 来源渠道 ")])]), _c('div', {
          staticClass: "cyui-list-extra"
        }, [_c('div', {
          staticClass: "cyui-rent-content-value"
        }, [_vm._v(" " + _vm._s(_vm.confirmParamsInfo.tenantSource) + " ")])]), _c('div', {
          staticClass: "cyui-list-arrow cyui-list-arrow-right"
        })])]), _c('div', {
          staticClass: "cyui-list-item cyui-rent-content-item",
          on: {
            "click": _vm.handleProfessionInfoShow
          }
        }, [_c('div', {
          staticClass: "cyui-list-line"
        }, [_c('div', {
          staticClass: "cyui-list-content"
        }, [_c('div', {
          staticClass: "cyui-rent-content-title"
        }, [_vm._v(" 职业 ")])]), _c('div', {
          staticClass: "cyui-list-extra"
        }, [_c('div', {
          staticClass: "cyui-rent-content-value"
        }, [_vm._v(" " + _vm._s(_vm._f("professionName")(_vm.confirmParamsInfo.profession, _vm.that)) + " ")])]), _c('div', {
          staticClass: "cyui-list-arrow cyui-list-arrow-right"
        })])]), _c('div', {
          staticClass: "cyui-list-item cyui-rent-content-item",
          on: {
            "click": function ($event) {
              `${_vm.status}` === '1' && (_vm.expectedDateInfo.show = true);
            }
          }
        }, [_c('div', {
          staticClass: "cyui-list-line"
        }, [_c('div', {
          staticClass: "cyui-list-content"
        }, [_c('div', {
          staticClass: "cyui-rent-content-title"
        }, [_vm._v(" 预计入住日期 ")])]), _c('div', {
          staticClass: "cyui-list-extra"
        }, [_c('div', {
          staticClass: "cyui-rent-content-value"
        }, [_vm._v(" " + _vm._s(_vm._f("formatParamsDate")(_vm.confirmParamsInfo.expectedDate)) + " ")])]), `${_vm.status}` === '1' ? _c('div', {
          staticClass: "cyui-list-arrow cyui-list-arrow-right"
        }) : _vm._e()])]), _c('div', {
          staticClass: "cyui-list-item cyui-rent-content-item",
          on: {
            "click": function ($event) {
              _vm.rentalTypeInfo.show = true;
            }
          }
        }, [_c('div', {
          staticClass: "cyui-list-line"
        }, [_c('div', {
          staticClass: "cyui-list-content"
        }, [_c('div', {
          staticClass: "cyui-rent-content-title"
        }, [_vm._v(" 付款方式 ")])]), _c('div', {
          staticClass: "cyui-list-extra"
        }, [_c('div', {
          staticClass: "cyui-rent-content-value"
        }, [_vm._v(" " + _vm._s(_vm.confirmParamsInfo.modeOfPayment) + " ")])]), _c('div', {
          staticClass: "cyui-list-arrow cyui-list-arrow-right"
        })])]), _c('div', {
          staticClass: "cyui-list-item cyui-rent-content-item"
        }, [_c('div', {
          staticClass: "cyui-list-line"
        }, [_c('div', {
          staticClass: "cyui-list-content"
        }, [_c('div', {
          staticClass: "cyui-rent-content-title"
        }, [_vm._v(" 房屋押金 ")])]), _c('div', {
          staticClass: "cyui-list-extra"
        }, [_c('van-field', {
          attrs: {
            "disabled": `${_vm.status}` === '2',
            "clearable": "",
            "input-align": "right",
            "placeholder": "请输入金额整数",
            "type": "digit"
          },
          scopedSlots: _vm._u([{
            key: "right-icon",
            fn: function () {
              return [_c('div', [_vm._v("元")])];
            },
            proxy: true
          }], null, false, 842794228),
          model: {
            value: _vm.confirmParamsInfo.deposit,
            callback: function ($$v) {
              _vm.$set(_vm.confirmParamsInfo, "deposit", $$v);
            },
            expression: "confirmParamsInfo.deposit"
          }
        })], 1)])]), _c('div', {
          staticClass: "cyui-list-item cyui-rent-content-item"
        }, [_c('div', {
          staticClass: "cyui-list-line"
        }, [_c('div', {
          staticClass: "cyui-list-content"
        }, [_c('div', {
          staticClass: "cyui-rent-content-title"
        }, [_vm._v(" 每月综合服务费 "), _vm.confirmParamsInfo.monthlyRentMSG ? _c('div', {
          staticClass: "cyui-describe"
        }, [_vm._v(" " + _vm._s(_vm.confirmParamsInfo.monthlyRentMSG) + " ")]) : _vm._e()])]), _c('div', {
          staticClass: "cyui-list-extra"
        }, [_c('van-field', {
          attrs: {
            "disabled": _vm.confirmParamsInfo.isSpecial === 1,
            "clearable": "",
            "input-align": "right",
            "placeholder": "请输入金额整数",
            "type": "digit"
          },
          on: {
            "blur": _vm.isMonthlyRentMsg,
            "input": _vm.handleMonthPriceInput
          },
          scopedSlots: _vm._u([{
            key: "right-icon",
            fn: function () {
              return [_c('div', [_vm._v("元")])];
            },
            proxy: true
          }], null, false, 842794228),
          model: {
            value: _vm.confirmParamsInfo.monthlyRent,
            callback: function ($$v) {
              _vm.$set(_vm.confirmParamsInfo, "monthlyRent", $$v);
            },
            expression: "confirmParamsInfo.monthlyRent"
          }
        }), _vm.amountPtfwf ? _c('div', {
          staticClass: "cyui-describe"
        }, [_vm._v("(包含配套服务费：" + _vm._s(_vm.amountPtfwf) + ")")]) : _vm._e()], 1)])]), _vm.amountLjqyf ? _c('div', {
          staticClass: "cyui-list-item cyui-rent-content-item"
        }, [_c('div', {
          staticClass: "cyui-list-line"
        }, [_c('div', {
          staticClass: "cyui-list-content"
        }, [_c('div', {
          staticClass: "cyui-rent-content-title"
        }, [_vm._v(" 垃圾清运费 ")])]), _c('div', {
          staticClass: "cyui-list-extra"
        }, [_c('van-field', {
          staticStyle: {
            "color": "red"
          },
          attrs: {
            "value": _vm.amountLjqyf,
            "clearable": "",
            "disabled": "",
            "input-align": "right",
            "type": "digit"
          },
          scopedSlots: _vm._u([{
            key: "right-icon",
            fn: function () {
              return [_c('div', [_vm._v("元")])];
            },
            proxy: true
          }], null, false, 842794228)
        })], 1)])]) : _vm._e(), _vm.floorPriceInfo.data && _vm.floorPriceInfo.data.state === 2 ? _c('div', {
          staticClass: "cyui-list-item cyui-rent-content-item"
        }, [_c('div', {
          staticClass: "cyui-list-line"
        }, [_c('div', {
          staticClass: "cyui-list-content"
        }, [_c('div', {
          staticClass: "cyui-rent-content-title"
        }, [_vm._v(" 房源底价 ")])]), _c('div', {
          staticClass: "cyui-list-extra"
        }, [_c('van-field', {
          staticStyle: {
            "color": "red"
          },
          attrs: {
            "value": _vm.floorPriceInfo.data.minAmount,
            "clearable": "",
            "disabled": "",
            "input-align": "right",
            "type": "digit"
          },
          scopedSlots: _vm._u([{
            key: "right-icon",
            fn: function () {
              return [_c('div', [_vm._v("元")])];
            },
            proxy: true
          }], null, false, 842794228)
        })], 1)])]) : _vm._e(), _vm.detailInfo.contractType === 2 ? _c('div', {
          staticClass: "cyui-list-item cyui-rent-content-item"
        }, [_c('div', {
          staticClass: "cyui-list-line"
        }, [_c('div', {
          staticClass: "cyui-list-content"
        }, [_c('div', {
          staticClass: "cyui-rent-content-title"
        }, [_vm._v(" 上次成交价 ")])]), _c('div', {
          staticClass: "cyui-list-extra"
        }, [_c('van-field', {
          attrs: {
            "value": _vm.latestContractPrice,
            "clearable": "",
            "disabled": "",
            "input-align": "right",
            "type": "digit"
          },
          scopedSlots: _vm._u([{
            key: "right-icon",
            fn: function () {
              return [_c('div', [_vm._v("元")])];
            },
            proxy: true
          }], null, false, 842794228)
        })], 1)])]) : _vm._e(), _vm.isPackagePrice === 2 ? _c('div', {
          staticClass: "cyui-list-item cyui-rent-content-item"
        }, [_c('div', {
          staticClass: "cyui-list-line"
        }, [_c('div', {
          staticClass: "cyui-list-content"
        }, [_c('div', {
          staticClass: "cyui-rent-content-title"
        }, [_vm._v(" 是否需要套餐 ")])]), _c('div', {
          staticClass: "cyui-list-extra",
          staticStyle: {
            "flex-basis": "auto"
          }
        }, [_c('van-radio-group', {
          attrs: {
            "direction": "horizontal",
            "icon-size": "18px"
          },
          on: {
            "change": _vm.onPackageStatusChange
          },
          model: {
            value: _vm.confirmParamsInfo.packageStatus,
            callback: function ($$v) {
              _vm.$set(_vm.confirmParamsInfo, "packageStatus", $$v);
            },
            expression: "confirmParamsInfo.packageStatus"
          }
        }, [_c('van-radio', {
          attrs: {
            "name": 2
          }
        }, [_vm._v("是")]), _c('van-radio', {
          attrs: {
            "name": 1
          }
        }, [_vm._v("否")])], 1)], 1)])]) : _vm._e(), _vm.packageList.length ? _c('div', {
          staticClass: "cyui-card-body",
          staticStyle: {
            "padding": "0"
          }
        }, [_c('div', {
          staticClass: "cyui-section-client"
        }, [_c('div', {
          staticClass: "cyui-house-list"
        }, [_c('van-cell-group', _vm._l(_vm.packageList, function (item) {
          return _c('van-cell', {
            key: item.packageId,
            on: {
              "click": function ($event) {
                return _vm.handleSelectPackage(item);
              }
            },
            scopedSlots: _vm._u([{
              key: "icon",
              fn: function () {
                return [_c('div', {
                  staticClass: "cyui-house-list-thumb"
                }, [_c('svg-icon', {
                  attrs: {
                    "icon-class": item.isSelected ? 'radio_checked' : 'radio'
                  }
                })], 1)];
              },
              proxy: true
            }, {
              key: "title",
              fn: function () {
                return [_c('div', {
                  staticClass: "cyui-flexbox cyui-house-item"
                }, [_c('div', {
                  staticClass: "cyui-flexbox-item"
                }, [_c('div', {
                  staticClass: "cyui-house-name"
                }, [_vm._v(_vm._s(item.packageName) + " "), _c('span', {
                  staticStyle: {
                    "color": "#bea57b"
                  }
                }, [_vm._v(_vm._s(item.packagePrice) + "元/月")])])])])];
              },
              proxy: true
            }], null, true)
          });
        }), 1)], 1)])]) : _vm._e(), _c('div', {
          staticClass: "cyui-list-item cyui-rent-content-item",
          staticStyle: {
            "display": "block"
          }
        }, [_c('div', {
          staticClass: "cyui-list-line",
          staticStyle: {
            "width": "100%"
          }
        }, [_c('div', {
          staticClass: "cyui-list-content"
        }, [_c('div', {
          staticClass: "cyui-rent-content-title"
        }, [_vm._v(" 租赁费用总额 "), _c('div', {
          staticClass: "cyui-describe"
        }, [_vm._v("(综合服务费总额)")])])]), _c('div', {
          staticClass: "cyui-list-extra"
        }, [_c('van-field', {
          attrs: {
            "value": _vm.confirmParamsInfo.totalFee,
            "clearable": "",
            "input-align": "right",
            "placeholder": "请输入金额整数",
            "readonly": "",
            "type": "digit"
          },
          scopedSlots: _vm._u([{
            key: "right-icon",
            fn: function () {
              return [_c('div', [_vm._v("元")])];
            },
            proxy: true
          }], null, false, 842794228)
        })], 1)]), _c('div', {
          staticClass: "cyui-describe"
        }, [_vm._v(_vm._s(_vm.confirmParamsInfo.priceDes))])]), `${_vm.status}` === '1' ? _c('div', {
          staticClass: "cyui-list-item cyui-rent-content-item"
        }, [_c('div', {
          staticClass: "cyui-list-line"
        }, [_c('div', {
          staticClass: "cyui-list-content"
        }, [_c('div', {
          staticClass: "cyui-rent-content-title"
        }, [_vm._v(" 入住水表度数 ")])]), _c('div', {
          staticClass: "cyui-list-extra"
        }, [_c('van-field', {
          attrs: {
            "input-align": "right",
            "placeholder": "请输入入住水表度数",
            "type": "number"
          },
          scopedSlots: _vm._u([{
            key: "right-icon",
            fn: function () {
              return [_c('div', [_vm._v("吨")])];
            },
            proxy: true
          }], null, false, 2346929567),
          model: {
            value: _vm.confirmParamsInfo.waterQuantity,
            callback: function ($$v) {
              _vm.$set(_vm.confirmParamsInfo, "waterQuantity", $$v);
            },
            expression: "confirmParamsInfo.waterQuantity"
          }
        })], 1)])]) : _vm._e(), `${_vm.status}` === '1' ? _c('div', {
          staticClass: "cyui-list-item cyui-rent-content-item"
        }, [_c('div', {
          staticClass: "cyui-list-line"
        }, [_c('div', {
          staticClass: "cyui-list-content"
        }, [_c('div', {
          staticClass: "cyui-rent-content-title"
        }, [_vm._v(" 入住电表度数 ")])]), _c('div', {
          staticClass: "cyui-list-extra"
        }, [_c('van-field', {
          attrs: {
            "input-align": "right",
            "placeholder": "请输入入住电表度数",
            "type": "number"
          },
          scopedSlots: _vm._u([{
            key: "right-icon",
            fn: function () {
              return [_c('div', [_vm._v("度")])];
            },
            proxy: true
          }], null, false, 2443981841),
          model: {
            value: _vm.confirmParamsInfo.electricQuantity,
            callback: function ($$v) {
              _vm.$set(_vm.confirmParamsInfo, "electricQuantity", $$v);
            },
            expression: "confirmParamsInfo.electricQuantity"
          }
        })], 1)])]) : _vm._e(), `${_vm.status}` === '1' ? _c('div', {
          staticClass: "cyui-list-item cyui-rent-content-item",
          on: {
            "click": function ($event) {
              `${_vm.status}` === '1' && (_vm.waterDateInfo.show = true);
            }
          }
        }, [_c('div', {
          staticClass: "cyui-list-line"
        }, [_c('div', {
          staticClass: "cyui-list-content"
        }, [_c('div', {
          staticClass: "cyui-rent-content-title"
        }, [_vm._v(" 入住水表时间 ")])]), _c('div', {
          staticClass: "cyui-list-extra"
        }, [_c('div', {
          staticClass: "cyui-rent-content-value"
        }, [_vm._v(" " + _vm._s(_vm._f("formatParamsDate")(_vm.confirmParamsInfo.waterRecordDate)) + " ")])]), `${_vm.status}` === '1' ? _c('div', {
          staticClass: "cyui-list-arrow cyui-list-arrow-right"
        }) : _vm._e()])]) : _vm._e(), `${_vm.status}` === '1' ? _c('div', {
          staticClass: "cyui-list-item cyui-rent-content-item",
          on: {
            "click": function ($event) {
              `${_vm.status}` === '1' && (_vm.electricDateInfo.show = true);
            }
          }
        }, [_c('div', {
          staticClass: "cyui-list-line"
        }, [_c('div', {
          staticClass: "cyui-list-content"
        }, [_c('div', {
          staticClass: "cyui-rent-content-title"
        }, [_vm._v(" 入住电表时间 ")])]), _c('div', {
          staticClass: "cyui-list-extra"
        }, [_c('div', {
          staticClass: "cyui-rent-content-value"
        }, [_vm._v(" " + _vm._s(_vm._f("formatParamsDate")(_vm.confirmParamsInfo.electricityRecordDate)) + " ")])]), `${_vm.status}` === '1' ? _c('div', {
          staticClass: "cyui-list-arrow cyui-list-arrow-right"
        }) : _vm._e()])]) : _vm._e(), `${_vm.status}` === '1' ? _c('div', {
          staticClass: "cyui-list-item cyui-rent-content-item"
        }, [_c('van-field', {
          attrs: {
            "input-align": "right",
            "name": "uploader"
          },
          scopedSlots: _vm._u([{
            key: "label",
            fn: function () {
              return [_c('span', {
                staticClass: "img-title",
                staticStyle: {
                  "font-size": "12px",
                  "color": "#999999"
                }
              }, [_vm._v("入住水表图片")])];
            },
            proxy: true
          }, {
            key: "input",
            fn: function () {
              return [_c('van-uploader', {
                attrs: {
                  "after-read": _vm.afterWaterIDRead,
                  "max-count": 1,
                  "max-size": 50 * 1024 * 1024
                },
                on: {
                  "delete": _vm.deleteWaterImg,
                  "oversize": _vm.onOversize
                },
                model: {
                  value: _vm.waterFileList,
                  callback: function ($$v) {
                    _vm.waterFileList = $$v;
                  },
                  expression: "waterFileList"
                }
              })];
            },
            proxy: true
          }], null, false, 270286641)
        })], 1) : _vm._e(), `${_vm.status}` === '1' ? _c('div', {
          staticClass: "cyui-list-item cyui-rent-content-item"
        }, [_c('van-field', {
          attrs: {
            "input-align": "right",
            "name": "uploader"
          },
          scopedSlots: _vm._u([{
            key: "label",
            fn: function () {
              return [_c('span', {
                staticClass: "img-title",
                staticStyle: {
                  "font-size": "12px",
                  "color": "#999999"
                }
              }, [_vm._v("入住电表图片")])];
            },
            proxy: true
          }, {
            key: "input",
            fn: function () {
              return [_c('van-uploader', {
                attrs: {
                  "after-read": _vm.afterElectricityIDRead,
                  "max-count": 1,
                  "max-size": 50 * 1024 * 1024
                },
                on: {
                  "delete": _vm.deleteElectricityrImg,
                  "oversize": _vm.onOversize
                },
                model: {
                  value: _vm.electricity,
                  callback: function ($$v) {
                    _vm.electricity = $$v;
                  },
                  expression: "electricity"
                }
              })];
            },
            proxy: true
          }], null, false, 1918263700)
        })], 1) : _vm._e(), _c('div', {
          staticClass: "cyui-list-item cyui-rent-content-item"
        }, [_c('div', {
          staticClass: "cyui-list-line"
        }, [_c('div', {
          staticClass: "cyui-list-content"
        }, [_c('div', {
          staticClass: "cyui-rent-content-title"
        }, [_vm._v(" 是否携带宠物 ")])]), _c('div', {
          staticClass: "cyui-list-extra",
          staticStyle: {
            "flex-basis": "auto"
          }
        }, [_c('van-radio-group', {
          attrs: {
            "direction": "horizontal",
            "icon-size": "18px"
          },
          model: {
            value: _vm.confirmParamsInfo.havePet,
            callback: function ($$v) {
              _vm.$set(_vm.confirmParamsInfo, "havePet", $$v);
            },
            expression: "confirmParamsInfo.havePet"
          }
        }, [_c('van-radio', {
          attrs: {
            "name": "1"
          },
          on: {
            "click": _vm.handleCarryPet
          }
        }, [_vm._v("是")]), _c('van-radio', {
          attrs: {
            "name": "0"
          }
        }, [_vm._v("否")])], 1)], 1)])]), _c('div', {
          staticClass: "cyui-list-item cyui-rent-content-item"
        }, [_c('div', {
          staticClass: "cyui-list-line"
        }, [_c('div', {
          staticClass: "cyui-list-content"
        }, [_c('div', {
          staticClass: "cyui-rent-content-title"
        }, [_vm._v(" 是否员工自住房 ")])]), _c('div', {
          staticClass: "cyui-list-extra",
          staticStyle: {
            "flex-basis": "auto"
          }
        }, [_c('van-radio-group', {
          attrs: {
            "direction": "horizontal",
            "icon-size": "18px"
          },
          model: {
            value: _vm.confirmParamsInfo.isSelfRoom,
            callback: function ($$v) {
              _vm.$set(_vm.confirmParamsInfo, "isSelfRoom", $$v);
            },
            expression: "confirmParamsInfo.isSelfRoom"
          }
        }, [_c('van-radio', {
          attrs: {
            "name": "2"
          }
        }, [_vm._v("是")]), _c('van-radio', {
          attrs: {
            "name": "1"
          }
        }, [_vm._v("否")])], 1)], 1)])]), _c('div', {
          directives: [{
            name: "show",
            rawName: "v-show",
            value: _vm.confirmParamsInfo.havePet === '1',
            expression: "confirmParamsInfo.havePet === '1'"
          }],
          staticClass: "cyui-list-item cyui-rent-content-item"
        }, [_c('div', {
          staticClass: "cyui-list-line"
        }, [_c('div', {
          staticClass: "cyui-list-content"
        }, [_c('div', {
          staticClass: "cyui-rent-content-title"
        }, [_vm._v(" 宠物押金 ")])]), _c('div', {
          staticClass: "cyui-list-extra"
        }, [_c('van-field', {
          attrs: {
            "disabled": true,
            "clearable": "",
            "input-align": "right",
            "type": "digit"
          },
          scopedSlots: _vm._u([{
            key: "right-icon",
            fn: function () {
              return [_c('div', [_vm._v("元")])];
            },
            proxy: true
          }], null, false, 842794228),
          model: {
            value: _vm.confirmParamsInfo.petDeposit,
            callback: function ($$v) {
              _vm.$set(_vm.confirmParamsInfo, "petDeposit", $$v);
            },
            expression: "confirmParamsInfo.petDeposit"
          }
        })], 1)])]), _vm.detailInfo.contractType === 1 || _vm.detailInfo.contractType === 2 ? _c('div', {
          staticClass: "cyui-list-item cyui-rent-content-item",
          on: {
            "click": _vm.handleOpenCouponScheme
          }
        }, [_c('div', {
          staticClass: "cyui-list-line"
        }, [_c('div', {
          staticClass: "cyui-list-content"
        }, [_c('div', {
          staticClass: "cyui-rent-content-title"
        }, [_vm._v(" 优惠方案 ")])]), _c('div', {
          staticClass: "cyui-list-extra"
        }, [_c('div', {
          staticClass: "cyui-rent-content-value"
        }, [_vm._v(" " + _vm._s(_vm.confirmParamsInfo.actCouponProgrammeName) + " ")])]), _c('div', {
          staticClass: "cyui-list-arrow cyui-list-arrow-right"
        })])]) : _vm._e(), _vm.detailInfo.contractType === 1 || _vm.detailInfo.contractType === 2 ? _c('div', {
          staticClass: "cyui-list-item cyui-rent-content-item",
          on: {
            "click": _vm.handleOpenCoupon
          }
        }, [_c('div', {
          staticClass: "cyui-list-line"
        }, [_c('div', {
          staticClass: "cyui-list-content"
        }, [_c('div', {
          staticClass: "cyui-rent-content-title"
        }, [_vm._v(" 优惠券 ")])]), _c('div', {
          staticClass: "cyui-list-extra"
        }, [_c('div', {
          staticClass: "cyui-rent-content-value"
        }, [_vm._v(" " + _vm._s(_vm.confirmParamsInfo.couponName) + " ")])]), _c('div', {
          staticClass: "cyui-list-arrow cyui-list-arrow-right"
        })])]) : _vm._e(), `${_vm.status}` === '1' && _vm.reportData.reportState ? _c('div', {
          staticClass: "cyui-list-item cyui-rent-content-item"
        }, [_c('div', {
          staticClass: "cyui-list-line"
        }, [_c('div', {
          staticClass: "cyui-list-content"
        }, [_c('div', {
          staticClass: "cyui-rent-content-title"
        }, [_vm._v(" 报备人 ")])]), _c('div', {
          staticClass: "cyui-list-extra"
        }, [_c('div', {
          staticClass: "cyui-rent-content-value"
        }, [_vm._v(" " + _vm._s(_vm.reportData.reportByName) + " ")])])])]) : _vm._e(), `${_vm.status}` === '1' && _vm.reportData.reportState ? _c('div', {
          staticClass: "cyui-card cyui-section-item"
        }, [_c('div', {
          staticClass: "cyui-card-header",
          staticStyle: {
            "padding": "0"
          }
        }, [_c('div', {
          staticClass: "cyui-card-header-line"
        }, [_c('div', {
          staticClass: "cyui-card-header-content",
          staticStyle: {
            "color": "#999999",
            "font-size": "12px"
          }
        }, [_vm._v(" 签约人本次成交角色为 ")])])]), _c('div', {
          staticClass: "cyui-card-body",
          staticStyle: {
            "padding": "0"
          }
        }, [_c('div', {
          staticClass: "cyui-section-client"
        }, [_c('div', {
          staticClass: "cyui-house-list"
        }, [_c('van-cell-group', [_c('van-cell', {
          on: {
            "click": function ($event) {
              _vm.confirmParamsInfo.beneficiary = 2;
            }
          },
          scopedSlots: _vm._u([{
            key: "icon",
            fn: function () {
              return [_c('div', {
                staticClass: "cyui-house-list-thumb"
              }, [_c('svg-icon', {
                attrs: {
                  "icon-class": _vm.confirmParamsInfo.beneficiary == 2 ? 'radio_checked' : 'radio'
                }
              })], 1)];
            },
            proxy: true
          }, {
            key: "title",
            fn: function () {
              return [_c('div', {
                staticClass: "cyui-flexbox cyui-house-item"
              }, [_c('div', {
                staticClass: "cyui-flexbox-item"
              }, [_c('div', {
                staticClass: "cyui-house-name"
              }, [_vm._v(" 辅助签约人 ")])])])];
            },
            proxy: true
          }], null, false, 2619724039)
        }), _c('van-cell', {
          on: {
            "click": function ($event) {
              _vm.confirmParamsInfo.beneficiary = 1;
            }
          },
          scopedSlots: _vm._u([{
            key: "icon",
            fn: function () {
              return [_c('div', {
                staticClass: "cyui-house-list-thumb"
              }, [_c('svg-icon', {
                attrs: {
                  "icon-class": _vm.confirmParamsInfo.beneficiary == 1 ? 'radio_checked' : 'radio'
                }
              })], 1)];
            },
            proxy: true
          }, {
            key: "title",
            fn: function () {
              return [_c('div', {
                staticClass: "cyui-flexbox cyui-house-item"
              }, [_c('div', {
                staticClass: "cyui-flexbox-item"
              }, [_c('div', {
                staticClass: "cyui-house-name"
              }, [_vm._v("主要成交人")])])])];
            },
            proxy: true
          }], null, false, 2609192318)
        })], 1)], 1)])])]) : _vm._e(), `${_vm.status}` === '1' ? _c('div', {
          staticClass: "cyui-list-item cyui-rent-content-item",
          on: {
            "click": function ($event) {
              _vm.stewardsInfo.show = true;
            }
          }
        }, [_c('div', {
          staticClass: "cyui-list-line"
        }, [_c('div', {
          staticClass: "cyui-list-content"
        }, [_c('div', {
          staticClass: "cyui-rent-content-title"
        }, [_vm._v(" 服务管家 ")])]), _c('div', {
          staticClass: "cyui-list-extra"
        }, [_c('div', {
          staticClass: "cyui-rent-content-value"
        }, [_vm._v(" " + _vm._s(_vm.confirmParamsInfo.serviceByName) + " ")])]), _c('div', {
          staticClass: "cyui-list-arrow cyui-list-arrow-right"
        })])]) : _vm._e(), _c('div', {
          staticClass: "cyui-list-item cyui-rent-content-item"
        }, [_c('div', {
          staticClass: "cyui-list-line"
        }, [_c('div', {
          staticClass: "cyui-list-content",
          staticStyle: {
            "height": "90%"
          }
        }, [_c('div', {
          staticClass: "cyui-rent-content-title"
        }, [_vm._v(" 备注 ")])]), _c('div', {
          staticClass: "cyui-list-area"
        }, [_c('van-field', {
          attrs: {
            "autosize": "",
            "maxlength": "50",
            "placeholder": "请输入备注",
            "rows": "2",
            "show-word-limit": "",
            "type": "textarea"
          },
          model: {
            value: _vm.confirmParamsInfo.remark,
            callback: function ($$v) {
              _vm.$set(_vm.confirmParamsInfo, "remark", $$v);
            },
            expression: "confirmParamsInfo.remark"
          }
        })], 1)])])])])])])])])] : _vm._e(), `${_vm.status}` === '4' || `${_vm.status}` === '5' || `${_vm.status}` === '9' ? [_vm.contractInfo.checkState !== 0 ? _c('div', {
          staticClass: "cyui-card cyui-section-item"
        }, [_c('div', {
          staticClass: "cyui-card-header"
        }, [_c('div', {
          staticClass: "cyui-card-header-line"
        }, [_c('div', {
          staticClass: "cyui-card-header-content"
        }, [_vm._v(" 审核信息 ")])])]), _c('div', {
          staticClass: "cyui-card-body",
          staticStyle: {
            "padding": "0"
          }
        }, [_c('div', {
          staticClass: "cyui-section-client"
        }, [_c('div', {
          staticClass: "cyui-rent"
        }, [_c('div', {
          staticClass: "cyui-list cyui-rent-content"
        }, [_c('div', {
          staticClass: "cyui-list-body"
        }, [_c('div', {
          staticClass: "cyui-list-item cyui-rent-content-item"
        }, [_c('div', {
          staticClass: "cyui-list-line"
        }, [_c('div', {
          staticClass: "cyui-list-content"
        }, [_c('div', {
          staticClass: "cyui-rent-content-title"
        }, [_vm._v(" 审核状态 ")])]), _c('div', {
          staticClass: "cyui-list-extra"
        }, [_vm.contractInfo.checkState === 1 ? _c('div', {
          staticClass: "cyui-rent-content-value"
        }, [_vm._v(" 审核中 ")]) : _vm.contractInfo.checkState === 2 ? _c('div', {
          staticClass: "cyui-rent-content-value"
        }, [_vm._v(" 审核通过 ")]) : _vm.contractInfo.checkState === 3 ? _c('div', {
          staticClass: "cyui-rent-content-value"
        }, [_vm._v(" 审核未通过 ")]) : _vm._e()])])]), _c('div', {
          staticClass: "cyui-list-item cyui-rent-content-item"
        }, [_c('div', {
          staticClass: "cyui-list-line"
        }, [_c('div', {
          staticClass: "cyui-list-content"
        }, [_c('div', {
          staticClass: "cyui-rent-content-title"
        }, [_vm._v(" 审核类型 ")])]), _c('div', {
          staticClass: "cyui-list-extra"
        }, [_vm.contractInfo.checkType === 1 ? _c('div', {
          staticClass: "cyui-rent-content-value"
        }, [_vm._v(" 新签破底价 ")]) : _vm._e(), _vm.contractInfo.checkType === 2 ? _c('div', {
          staticClass: "cyui-rent-content-value"
        }, [_vm._v(" 续签价格过低 ")]) : _vm._e()])])]), _c('div', {
          staticClass: "cyui-list-item cyui-rent-content-item"
        }, [_c('div', {
          staticClass: "cyui-list-line"
        }, [_c('div', {
          staticClass: "cyui-list-content"
        }, [_c('div', {
          staticClass: "cyui-rent-content-title"
        }, [_vm._v(" 提交审核时间 ")])]), _c('div', {
          staticClass: "cyui-list-extra"
        }, [_c('div', {
          staticClass: "cyui-rent-content-value"
        }, [_vm._v(" " + _vm._s(_vm.contractInfo.checkSubmitTime) + " ")])])])]), _vm.contractInfo.checkType === 1 ? _c('div', {
          staticClass: "cyui-list-item cyui-rent-content-item"
        }, [_c('div', {
          staticClass: "cyui-list-line"
        }, [_c('div', {
          staticClass: "cyui-list-content"
        }, [_c('div', {
          staticClass: "cyui-rent-content-title"
        }, [_vm._v(" 审核底价价格 ")])]), _c('div', {
          staticClass: "cyui-list-extra"
        }, [_c('div', {
          staticClass: "cyui-rent-content-value"
        }, [_vm._v(" " + _vm._s(_vm.contractInfo.checkPriceMin) + " ")])])])]) : _vm._e(), _vm.contractInfo.checkType === 2 ? _c('div', {
          staticClass: "cyui-list-item cyui-rent-content-item"
        }, [_c('div', {
          staticClass: "cyui-list-line"
        }, [_c('div', {
          staticClass: "cyui-list-content"
        }, [_c('div', {
          staticClass: "cyui-rent-content-title"
        }, [_vm._v(" 审核续签价格 ")])]), _c('div', {
          staticClass: "cyui-list-extra"
        }, [_c('div', {
          staticClass: "cyui-rent-content-value"
        }, [_vm._v(" " + _vm._s(_vm.contractInfo.checkPriceRenew) + " ")])])])]) : _vm._e()])])])])])]) : _vm._e(), _c('div', {
          staticClass: "cyui-card cyui-section-item"
        }, [_c('div', {
          staticClass: "cyui-card-header"
        }, [_c('div', {
          staticClass: "cyui-card-header-line"
        }, [_c('div', {
          staticClass: "cyui-card-header-content"
        }, [_vm._v(" 租赁信息 ")])])]), _c('div', {
          staticClass: "cyui-card-body",
          staticStyle: {
            "padding": "0"
          }
        }, [_c('div', {
          staticClass: "cyui-section-client"
        }, [_c('div', {
          staticClass: "cyui-rent"
        }, [_c('div', {
          staticClass: "cyui-list cyui-rent-content"
        }, [_c('div', {
          staticClass: "cyui-list-body"
        }, [_c('div', {
          staticClass: "cyui-list-item cyui-rent-content-item"
        }, [_c('div', {
          staticClass: "cyui-list-line"
        }, [_c('div', {
          staticClass: "cyui-list-content"
        }, [_c('div', {
          staticClass: "cyui-rent-content-title"
        }, [_vm._v(" 租赁开始日期 ")])]), _c('div', {
          staticClass: "cyui-list-extra"
        }, [_c('div', {
          staticClass: "cyui-rent-content-value"
        }, [_vm._v(" " + _vm._s(_vm._f("formatParamsDate")(_vm.contractInfo.startDate)) + " ")])])])]), _c('div', {
          staticClass: "cyui-list-item cyui-rent-content-item"
        }, [_c('div', {
          staticClass: "cyui-list-line"
        }, [_c('div', {
          staticClass: "cyui-list-content"
        }, [_c('div', {
          staticClass: "cyui-rent-content-title"
        }, [_vm._v(" 租赁结束日期 ")])]), _c('div', {
          staticClass: "cyui-list-extra"
        }, [_c('div', {
          staticClass: "cyui-rent-content-value"
        }, [_vm._v(" " + _vm._s(_vm._f("formatParamsDate")(_vm.contractInfo.endDate)) + " ")])])])]), _c('div', {
          staticClass: "cyui-list-item cyui-rent-content-item"
        }, [_c('div', {
          staticClass: "cyui-list-line"
        }, [_c('div', {
          staticClass: "cyui-list-content"
        }, [_c('div', {
          staticClass: "cyui-rent-content-title"
        }, [_vm._v(" 预计入住日期 ")])]), _c('div', {
          staticClass: "cyui-list-extra"
        }, [_c('div', {
          staticClass: "cyui-rent-content-value"
        }, [_vm._v(" " + _vm._s(_vm._f("formatParamsDate")(_vm.contractInfo.expectedDate)) + " ")])])])]), _c('div', {
          staticClass: "cyui-list-item cyui-rent-content-item"
        }, [_c('div', {
          staticClass: "cyui-list-line"
        }, [_c('div', {
          staticClass: "cyui-list-content"
        }, [_c('div', {
          staticClass: "cyui-rent-content-title"
        }, [_vm._v(" 付款方式 ")])]), _c('div', {
          staticClass: "cyui-list-extra"
        }, [_c('div', {
          staticClass: "cyui-rent-content-value"
        }, [_vm._v(" " + _vm._s(_vm.contractInfo.modeOfPayment) + " ")])])])]), _c('div', {
          staticClass: "cyui-list-item cyui-rent-content-item"
        }, [_c('div', {
          staticClass: "cyui-list-line"
        }, [_c('div', {
          staticClass: "cyui-list-content"
        }, [_c('div', {
          staticClass: "cyui-rent-content-title"
        }, [_vm._v(" 来源渠道 ")])]), _c('div', {
          staticClass: "cyui-list-extra"
        }, [_c('div', {
          staticClass: "cyui-rent-content-value"
        }, [_vm._v(" " + _vm._s(_vm.contractInfo.tenantSource) + " ")])])])]), _c('div', {
          staticClass: "cyui-list-item cyui-rent-content-item"
        }, [_c('div', {
          staticClass: "cyui-list-line"
        }, [_c('div', {
          staticClass: "cyui-list-content"
        }, [_c('div', {
          staticClass: "cyui-rent-content-title"
        }, [_vm._v(" 职业 ")])]), _c('div', {
          staticClass: "cyui-list-extra"
        }, [_c('div', {
          staticClass: "cyui-rent-content-value"
        }, [_vm._v(" " + _vm._s(_vm._f("professionName")(_vm.contractInfo.profession, _vm.that)) + " ")])])])]), _c('div', {
          staticClass: "cyui-list-item cyui-rent-content-item"
        }, [_c('div', {
          staticClass: "cyui-list-line"
        }, [_c('div', {
          staticClass: "cyui-list-content"
        }, [_c('div', {
          staticClass: "cyui-rent-content-title"
        }, [_vm._v(" 房屋押金 ")])]), _c('div', {
          staticClass: "cyui-list-extra"
        }, [_c('div', {
          staticClass: "cyui-rent-content-value"
        }, [_vm._v(" " + _vm._s(_vm._f("formatConfirmParamsMoney")(_vm.contractInfo.deposit)) + " ")])])])]), _c('div', {
          staticClass: "cyui-list-item cyui-rent-content-item"
        }, [_c('div', {
          staticClass: "cyui-list-line"
        }, [_c('div', {
          staticClass: "cyui-list-content"
        }, [_c('div', {
          staticClass: "cyui-rent-content-title"
        }, [_vm._v(" 每月综合服务费 ")])]), _c('div', {
          staticClass: "cyui-list-extra"
        }, [_c('div', {
          staticClass: "cyui-rent-content-value"
        }, [_vm._v(" " + _vm._s(_vm._f("formatConfirmParamsMoney")(_vm.contractInfo.monthlyRent)) + " ")])])])]), _c('div', {
          staticClass: "cyui-list-item cyui-rent-content-item"
        }, [_c('div', {
          staticClass: "cyui-list-line"
        }, [_c('div', {
          staticClass: "cyui-list-content"
        }, [_c('div', {
          staticClass: "cyui-rent-content-title"
        }, [_vm._v(" 租赁费用总额 "), _c('div', {
          staticClass: "cyui-describe"
        }, [_vm._v("(综合服务费总额)")])])]), _c('div', {
          staticClass: "cyui-list-extra"
        }, [_c('div', {
          staticClass: "cyui-rent-content-value"
        }, [_vm._v(" " + _vm._s(_vm._f("formatConfirmParamsMoney")(_vm.contractInfo.totalFee)) + " ")])])])]), _c('div', {
          staticClass: "cyui-list-item cyui-rent-content-item"
        }, [_c('div', {
          staticClass: "cyui-list-line"
        }, [_c('div', {
          staticClass: "cyui-list-content"
        }, [_c('div', {
          staticClass: "cyui-rent-content-title"
        }, [_vm._v(" 入住水表度数 ")])]), _c('div', {
          staticClass: "cyui-list-extra"
        }, [_c('div', {
          staticClass: "cyui-rent-content-value"
        }, [_vm._v(" " + _vm._s(_vm.contractInfo.waterQuantity) + " ")])])])]), _c('div', {
          staticClass: "cyui-list-item cyui-rent-content-item"
        }, [_c('div', {
          staticClass: "cyui-list-line"
        }, [_c('div', {
          staticClass: "cyui-list-content"
        }, [_c('div', {
          staticClass: "cyui-rent-content-title"
        }, [_vm._v(" 入住电表度数 ")])]), _c('div', {
          staticClass: "cyui-list-extra"
        }, [_c('div', {
          staticClass: "cyui-rent-content-value"
        }, [_vm._v(" " + _vm._s(_vm.contractInfo.electricQuantity) + " ")])])])]), _c('div', {
          staticClass: "cyui-list-item cyui-rent-content-item"
        }, [_c('div', {
          staticClass: "cyui-list-line"
        }, [_c('div', {
          staticClass: "cyui-list-content"
        }, [_c('div', {
          staticClass: "cyui-rent-content-title"
        }, [_vm._v(" 入住水表时间 ")])]), _c('div', {
          staticClass: "cyui-list-extra"
        }, [_c('div', {
          staticClass: "cyui-rent-content-value"
        }, [_vm._v(" " + _vm._s(_vm._f("formatParamsDate")(_vm.contractInfo.waterRecordDate)) + " ")])])])]), _c('div', {
          staticClass: "cyui-list-item cyui-rent-content-item"
        }, [_c('div', {
          staticClass: "cyui-list-line"
        }, [_c('div', {
          staticClass: "cyui-list-content"
        }, [_c('div', {
          staticClass: "cyui-rent-content-title"
        }, [_vm._v(" 入住电表时间 ")])]), _c('div', {
          staticClass: "cyui-list-extra"
        }, [_c('div', {
          staticClass: "cyui-rent-content-value"
        }, [_vm._v(" " + _vm._s(_vm._f("formatParamsDate")(_vm.contractInfo.electricityRecordDate)) + " ")])])])]), _c('div', {
          staticClass: "cyui-list-item cyui-rent-content-item"
        }, [_c('div', {
          staticClass: "cyui-list-line"
        }, [_c('div', {
          staticClass: "cyui-list-content"
        }, [_c('div', {
          staticClass: "cyui-rent-content-title"
        }, [_vm._v(" 入住水表图片 ")])]), _c('div', {
          staticClass: "cyui-list-extra"
        }, [_c('van-image', {
          attrs: {
            "src": _vm.contractInfo.checkInWaterPicture,
            "height": "80",
            "lazy-load": "",
            "width": "80"
          },
          on: {
            "click": function ($event) {
              return _vm.reviewImg(_vm.contractInfo.checkInWaterPicture);
            }
          }
        })], 1)])]), _c('div', {
          staticClass: "cyui-list-item cyui-rent-content-item"
        }, [_c('div', {
          staticClass: "cyui-list-line"
        }, [_c('div', {
          staticClass: "cyui-list-content"
        }, [_c('div', {
          staticClass: "cyui-rent-content-title"
        }, [_vm._v(" 入住电表图片 ")])]), _c('div', {
          staticClass: "cyui-list-extra"
        }, [_c('van-image', {
          attrs: {
            "src": _vm.contractInfo.checkInElectricityPicture,
            "height": "80",
            "lazy-load": "",
            "width": "80"
          },
          on: {
            "click": function ($event) {
              return _vm.reviewImg(_vm.contractInfo.checkInElectricityPicture);
            }
          }
        })], 1)])]), _vm.isPackagePrice === 2 ? _c('div', {
          staticClass: "cyui-list-item cyui-rent-content-item"
        }, [_c('div', {
          staticClass: "cyui-list-line"
        }, [_c('div', {
          staticClass: "cyui-list-content"
        }, [_c('div', {
          staticClass: "cyui-rent-content-title"
        }, [_vm._v(" 是否需要套餐 ")])]), _c('div', {
          staticClass: "cyui-list-extra"
        }, [_c('div', {
          staticClass: "cyui-rent-content-value"
        }, [_vm._v(" " + _vm._s(_vm.contractInfo.packageStatus === 1 ? '关闭' : '开启') + " ")])])])]) : _vm._e(), _c('div', {
          staticClass: "cyui-list-item cyui-rent-content-item"
        }, [_c('div', {
          staticClass: "cyui-list-line"
        }, [_c('div', {
          staticClass: "cyui-list-content"
        }, [_c('div', {
          staticClass: "cyui-rent-content-title"
        }, [_vm._v(" 是否员工自住房 ")])]), _c('div', {
          staticClass: "cyui-list-extra"
        }, [_c('div', {
          staticClass: "cyui-rent-content-value"
        }, [_vm._v(" " + _vm._s(_vm.contractInfo.isSelfRoom == 2 ? '是' : '否') + " ")])])])]), _c('div', {
          directives: [{
            name: "show",
            rawName: "v-show",
            value: _vm.contractInfo.petDeposit !== 0,
            expression: "contractInfo.petDeposit !== 0"
          }],
          staticClass: "cyui-list-item cyui-rent-content-item"
        }, [_c('div', {
          staticClass: "cyui-list-line"
        }, [_c('div', {
          staticClass: "cyui-list-content"
        }, [_c('div', {
          staticClass: "cyui-rent-content-title"
        }, [_vm._v(" 宠物押金 ")])]), _c('div', {
          staticClass: "cyui-list-extra"
        }, [_c('div', {
          staticClass: "cyui-rent-content-value"
        }, [_vm._v(" " + _vm._s(_vm._f("formatConfirmParamsMoney")(_vm.contractInfo.petDeposit)) + " ")])])])]), _c('div', {
          staticClass: "cyui-list-item cyui-rent-content-item"
        }, [_c('div', {
          staticClass: "cyui-list-line"
        }, [_c('div', {
          staticClass: "cyui-list-content"
        }, [_c('div', {
          staticClass: "cyui-rent-content-title"
        }, [_vm._v(" 备注 ")])]), _c('div', {
          staticClass: "cyui-list-extra"
        }, [_c('div', {
          staticClass: "cyui-rent-content-value"
        }, [_vm._v(" " + _vm._s(_vm.contractInfo.remark) + " ")])])])])]), _vm.contractInfo.reportById != '' ? _c('div', {
          staticClass: "cyui-list-item cyui-rent-content-item"
        }, [_c('div', {
          staticClass: "cyui-list-line"
        }, [_c('div', {
          staticClass: "cyui-list-content"
        }, [_c('div', {
          staticClass: "cyui-rent-content-title"
        }, [_vm._v(" 报备人 ")])]), _c('div', {
          staticClass: "cyui-list-extra"
        }, [_c('div', {
          staticClass: "cyui-rent-content-value"
        }, [_vm._v(" " + _vm._s(_vm.contractInfo.reportByName) + " ")])])])]) : _vm._e(), _c('div', {
          staticClass: "cyui-list-item cyui-rent-content-item"
        }, [_c('div', {
          staticClass: "cyui-list-line"
        }, [_c('div', {
          staticClass: "cyui-list-content"
        }, [_c('div', {
          staticClass: "cyui-rent-content-title"
        }, [_vm._v(" 签约人本次成交角色为 ")])]), _c('div', {
          staticClass: "cyui-list-extra"
        }, [_c('div', {
          staticClass: "cyui-rent-content-value"
        }, [_vm.contractInfo.beneficiary == 2 ? _c('span', [_vm._v(" 辅助签约人 ")]) : _vm._e(), _vm.contractInfo.beneficiary == 1 ? _c('span', [_vm._v(" 主要成交人 ")]) : _vm._e()])])])]), _c('div', {
          staticClass: "cyui-list-item cyui-rent-content-item"
        }, [_c('div', {
          staticClass: "cyui-list-line"
        }, [_c('div', {
          staticClass: "cyui-list-content"
        }, [_c('div', {
          staticClass: "cyui-rent-content-title"
        }, [_vm._v(" 服务管家 ")])]), _c('div', {
          staticClass: "cyui-list-extra"
        }, [_c('div', {
          staticClass: "cyui-rent-content-value"
        }, [_vm._v(" " + _vm._s(_vm.contractInfo.serviceByName) + " ")])])])])])])])])]), _c('div', {
          staticClass: "cyui-card cyui-section-item"
        }, [_c('div', {
          staticClass: "cyui-card-header"
        }, [_c('div', {
          staticClass: "cyui-card-header-line"
        }, [_c('div', {
          staticClass: "cyui-card-header-content"
        }, [_vm._v(" 房源选择 ")])])]), _c('div', {
          staticClass: "cyui-card-body",
          staticStyle: {
            "padding": "0"
          }
        }, [_c('div', {
          staticClass: "cyui-section-client"
        }, [_c('div', {
          staticClass: "cyui-rent"
        }, [_c('div', {
          staticClass: "cyui-list cyui-rent-content"
        }, [_c('div', {
          staticClass: "cyui-list-body"
        }, [_c('div', {
          staticClass: "cyui-list-item cyui-rent-content-item"
        }, [_c('div', {
          staticClass: "cyui-list-line"
        }, [_c('div', {
          staticClass: "cyui-list-content"
        }, [_c('div', {
          staticClass: "cyui-rent-content-title"
        }, [_vm._v(" " + _vm._s(_vm.contractInfo.projectName || '-') + " " + _vm._s(_vm.contractInfo.roomNo || '-') + " ")])])])])])])])])])])] : _vm._e(), _c('div', {
          staticClass: "cyui-card cyui-section-item"
        }, [_c('div', {
          staticClass: "cyui-card-body",
          staticStyle: {
            "padding": "0"
          }
        }, [_c('div', {
          staticClass: "cyui-section-client"
        }, [_c('div', {
          staticClass: "cyui-staff-info"
        }, [_c('div', {
          staticClass: "cyui-staff-info-body"
        }, [_c('div', {
          staticClass: "cyui-list"
        }, [_c('div', {
          staticClass: "cyui-list-body"
        }, [_c('div', {
          staticClass: "cyui-list-item"
        }, [_c('div', {
          staticClass: "cyui-list-line"
        }, [_c('div', {
          staticClass: "cyui-list-label cyui-list-label-5"
        }, [_vm._v("申请时间：")]), _c('div', {
          staticClass: "cyui-list-content"
        }, [_vm._v(_vm._s(_vm.detailInfo.createTime || '-'))])])]), `${_vm.status}` === '4' || `${_vm.status}` === '5' || `${_vm.status}` === '9' ? _c('div', {
          staticClass: "cyui-list-item"
        }, [_c('div', {
          staticClass: "cyui-list-line"
        }, [_c('div', {
          staticClass: "cyui-list-label cyui-list-label-5"
        }, [_vm._v("管家确认：")]), _c('div', {
          staticClass: "cyui-list-content"
        }, [_vm._v(_vm._s(_vm.detailInfo.passTime || '-'))])])]) : _vm._e(), (`${_vm.status}` === '1' || `${_vm.status}` === '2') && _vm.couponList.length ? _c('div', [_c('div', {
          staticClass: "cyui-list-label cyui-list-label-5"
        }, [_vm._v("该用户名下优惠券：")]), _c('div', {
          staticClass: "scroll"
        }, [_c('table', {
          staticClass: "spec_table table"
        }, [_c('thead', [_c('th', [_vm._v("租期门槛")]), _c('th', [_vm._v("优惠金额")]), _c('th', [_vm._v("名称")]), _c('th', [_vm._v("有效期至")]), _c('th', [_vm._v("租金门槛")])]), _c('tbody', _vm._l(_vm.couponList, function (item, index) {
          return _c('tr', {
            key: index
          }, [_c('td', [item.monthLimit ? _c('span', [_vm._v("租期满" + _vm._s(item.monthLimit) + "个月可用")]) : _c('span', [_vm._v("不限")])]), _c('td', [_vm._v(_vm._s(item.discountMoney ? item.discountMoney + '元' : '-'))]), _c('td', [_vm._v(_vm._s(item.couponName || '-'))]), _c('td', [_vm._v(_vm._s(item.failureTime || '-'))]), _c('td', [item.moneyRequirement ? _c('span', [_vm._v("满" + _vm._s(item.moneyRequirement) + "元可用")]) : _c('span', [_vm._v("不限")])])]);
        }), 0)])])]) : _vm._e(), `${_vm.status}` === '5' || `${_vm.status}` === '9' ? _c('div', {
          staticClass: "cyui-list-item"
        }, [_c('div', {
          staticClass: "cyui-list-line"
        }, [_c('div', {
          staticClass: "cyui-list-label cyui-list-label-5"
        }, [_vm._v("签约完成：")]), _c('div', {
          staticClass: "cyui-list-content"
        }, [_vm._v(_vm._s(_vm.detailInfo.successTime || '-'))])])]) : _vm._e(), `${_vm.status}` === '9' ? _c('div', {
          staticClass: "cyui-list-item"
        }, [_c('div', {
          staticClass: "cyui-list-line"
        }, [_c('div', {
          staticClass: "cyui-list-label cyui-list-label-5"
        }, [_vm._v("支付时间：")]), _c('div', {
          staticClass: "cyui-list-content"
        }, [_vm._v(_vm._s(_vm.detailInfo.payPaidTime || '-'))])])]) : _vm._e(), `${_vm.status}` === '3' || `${_vm.status}` === '6' || `${_vm.status}` === '7' || `${_vm.status}` === '8' || `${_vm.status}` === '10' ? [_c('div', {
          staticClass: "cyui-list-item"
        }, [_c('div', {
          staticClass: "cyui-list-line"
        }, [_c('div', {
          staticClass: "cyui-list-label cyui-list-label-5"
        }, [_vm._v(" " + _vm._s(`${_vm.status}` === '10' ? '失效时间：' : '取消时间：') + " ")]), _c('div', {
          staticClass: "cyui-list-content"
        }, [_vm._v(_vm._s(_vm.detailInfo.failureTime || '-'))])])])] : _vm._e()], 2)])])])])])])], 2), _c('div', {
          staticClass: "cyui-sign-contract-btns"
        }, [`${_vm.status}` === '1' || `${_vm.status}` === '2' ? [_c('div', {
          staticClass: "cyui-btn cyui-btn-primary cyui-btn-block",
          on: {
            "click": _vm.handleConfirmSignContract
          }
        }, [_vm._v(" 确认信息 ")]), _c('div', {
          staticClass: "cyui-btn cyui-btn-ghost cyui-btn-block",
          on: {
            "click": _vm.handleCancelSignContract
          }
        }, [_vm._v(" 取消签约 ")])] : `${_vm.status}` === '4' ? [_c('a', {
          staticClass: "cyui-btn cyui-btn-primary cyui-btn-block",
          attrs: {
            "href": `tel:${_vm.detailInfo.stewardMobile || '#'}`
          }
        }, [_vm._v(" 联系客户 ")]), _c('div', {
          staticClass: "cyui-btn cyui-btn-ghost cyui-btn-block",
          on: {
            "click": _vm.handleCancelSignContract
          }
        }, [_vm._v(" 取消签约 ")]), _vm.detailInfo.signSource === 3 ? _c('div', {
          staticClass: "cyui-btn cyui-btn-ghost cyui-btn-block",
          on: {
            "click": _vm.handleCopyUrl
          }
        }, [_vm._v(" 复制合同签署链接 ")]) : _vm._e()] : `${_vm.status}` === '5' || `${_vm.status}` === '9' ? [_c('div', {
          staticClass: "cyui-btn cyui-btn-primary cyui-btn-block",
          on: {
            "click": _vm.handleViewContract
          }
        }, [_vm._v(" 查看合同 ")])] : _vm._e()], 2)];
      },
      proxy: true
    }, {
      key: "footer",
      fn: function () {
        return undefined;
      },
      proxy: true
    }])
  }), _c('van-calendar', {
    ref: "rentalDateCalendar",
    attrs: {
      "default-date": _vm.confirmCalendarInfo.defaultDate,
      "get-container": _vm.getContainer,
      "max-date": _vm.confirmCalendarInfo.maxDate,
      "min-date": _vm.confirmCalendarInfo.minDate,
      "color": "#D1A36F",
      "type": "single"
    },
    on: {
      "open": _vm.openCalendar,
      "confirm": _vm.handleSelectCalendar
    },
    model: {
      value: _vm.confirmCalendarInfo.show,
      callback: function ($$v) {
        _vm.$set(_vm.confirmCalendarInfo, "show", $$v);
      },
      expression: "confirmCalendarInfo.show"
    }
  }), _c('van-calendar', {
    ref: "expectedDateCalendar",
    attrs: {
      "default-date": _vm.expectedDateInfo.defaultDate,
      "get-container": _vm.getContainer,
      "max-date": _vm.expectedDateInfo.maxDate,
      "min-date": _vm.expectedDateInfo.minDate,
      "color": "#D1A36F",
      "type": "single"
    },
    on: {
      "confirm": _vm.handleSelectExpectedDate
    },
    model: {
      value: _vm.expectedDateInfo.show,
      callback: function ($$v) {
        _vm.$set(_vm.expectedDateInfo, "show", $$v);
      },
      expression: "expectedDateInfo.show"
    }
  }), _c('van-calendar', {
    ref: "electricDateCalendar",
    attrs: {
      "default-date": _vm.electricDateInfo.defaultDate,
      "get-container": _vm.getContainer,
      "max-date": _vm.electricDateInfo.maxDate,
      "min-date": _vm.electricDateInfo.minDate,
      "color": "#D1A36F",
      "type": "single"
    },
    on: {
      "confirm": _vm.handleSelectElectricDateInfoDate
    },
    model: {
      value: _vm.electricDateInfo.show,
      callback: function ($$v) {
        _vm.$set(_vm.electricDateInfo, "show", $$v);
      },
      expression: "electricDateInfo.show"
    }
  }), _c('van-calendar', {
    ref: "waterDateCalendar",
    attrs: {
      "default-date": _vm.waterDateInfo.defaultDate,
      "get-container": _vm.getContainer,
      "max-date": _vm.waterDateInfo.maxDate,
      "min-date": _vm.waterDateInfo.minDate,
      "color": "#D1A36F",
      "type": "single"
    },
    on: {
      "confirm": _vm.handleSelectWaterDateInfoDate
    },
    model: {
      value: _vm.waterDateInfo.show,
      callback: function ($$v) {
        _vm.$set(_vm.waterDateInfo, "show", $$v);
      },
      expression: "waterDateInfo.show"
    }
  }), _c('van-popup', {
    attrs: {
      "get-container": _vm.getContainer,
      "position": "bottom"
    },
    model: {
      value: _vm.batchInfo.show,
      callback: function ($$v) {
        _vm.$set(_vm.batchInfo, "show", $$v);
      },
      expression: "batchInfo.show"
    }
  }, [_c('van-picker', {
    attrs: {
      "columns": _vm.batchInfo.listData,
      "title": _vm.batchInfo.title,
      "item-height": "1.22667rem",
      "value-key": "batchName",
      "show-toolbar": ""
    },
    on: {
      "cancel": function ($event) {
        _vm.batchInfo.show = false;
      },
      "confirm": _vm.handleBatch
    }
  })], 1), _c('van-popup', {
    attrs: {
      "get-container": _vm.getContainer,
      "position": "bottom"
    },
    model: {
      value: _vm.clinchDealSourceInfo.show,
      callback: function ($$v) {
        _vm.$set(_vm.clinchDealSourceInfo, "show", $$v);
      },
      expression: "clinchDealSourceInfo.show"
    }
  }, [_c('van-picker', {
    attrs: {
      "columns": _vm.clinchDealSourceInfo.listData,
      "title": _vm.clinchDealSourceInfo.title,
      "item-height": "1.22667rem",
      "show-toolbar": ""
    },
    on: {
      "cancel": function ($event) {
        _vm.clinchDealSourceInfo.show = false;
      },
      "confirm": _vm.handleRentalSourceType
    }
  })], 1), _c('van-popup', {
    attrs: {
      "get-container": _vm.getContainer,
      "position": "bottom"
    },
    model: {
      value: _vm.professionInfo.show,
      callback: function ($$v) {
        _vm.$set(_vm.professionInfo, "show", $$v);
      },
      expression: "professionInfo.show"
    }
  }, [_c('van-search', {
    attrs: {
      "placeholder": "请输入职业"
    },
    on: {
      "input": _vm.handleProfessionSearch
    },
    model: {
      value: _vm.professionInfo.search,
      callback: function ($$v) {
        _vm.$set(_vm.professionInfo, "search", $$v);
      },
      expression: "professionInfo.search"
    }
  }), _c('van-picker', {
    attrs: {
      "columns": _vm.professionInfo.listData,
      "title": _vm.professionInfo.title,
      "item-height": "1.22667rem",
      "show-toolbar": ""
    },
    on: {
      "cancel": function ($event) {
        _vm.professionInfo.show = false;
      },
      "confirm": _vm.handleProfessionInfo
    }
  })], 1), _c('van-popup', {
    attrs: {
      "get-container": _vm.getContainer,
      "position": "bottom"
    },
    model: {
      value: _vm.rentalTypeInfo.show,
      callback: function ($$v) {
        _vm.$set(_vm.rentalTypeInfo, "show", $$v);
      },
      expression: "rentalTypeInfo.show"
    }
  }, [_c('van-picker', {
    attrs: {
      "columns": _vm.rentalTypeInfo.listData,
      "title": _vm.rentalTypeInfo.title,
      "item-height": "1.22667rem",
      "show-toolbar": ""
    },
    on: {
      "cancel": function ($event) {
        _vm.rentalTypeInfo.show = false;
      },
      "confirm": _vm.handleSelectRentalType
    }
  })], 1), _c('van-dialog', {
    attrs: {
      "before-close": _vm.floorPriceBeforeClose,
      "confirm-button-color": "#fff",
      "show-cancel-button": ""
    },
    scopedSlots: _vm._u([{
      key: "title",
      fn: function () {
        return [_c('p', [_vm._v("提示")]), _c('p', [_vm._v("您填写的每月房屋租金+每月服务费已经低于规定的定价/底价，请再次确认并阐述原因。")])];
      },
      proxy: true
    }]),
    model: {
      value: _vm.floorPriceInfo.show,
      callback: function ($$v) {
        _vm.$set(_vm.floorPriceInfo, "show", $$v);
      },
      expression: "floorPriceInfo.show"
    }
  }, [_c('van-form', [_c('van-field', {
    staticClass: "my-textarea",
    attrs: {
      "autofocus": "",
      "autosize": "",
      "label": "原因",
      "label-width": "30",
      "maxlength": "200",
      "placeholder": "原因必填",
      "required": "",
      "rows": "3",
      "show-word-limit": "",
      "type": "textarea"
    },
    model: {
      value: _vm.confirmParamsInfo.amountRemark,
      callback: function ($$v) {
        _vm.$set(_vm.confirmParamsInfo, "amountRemark", $$v);
      },
      expression: "confirmParamsInfo.amountRemark"
    }
  })], 1)], 1), _c('van-popup', {
    attrs: {
      "get-container": _vm.getContainer,
      "position": "bottom"
    },
    model: {
      value: _vm.couponSchemeInfo.show,
      callback: function ($$v) {
        _vm.$set(_vm.couponSchemeInfo, "show", $$v);
      },
      expression: "couponSchemeInfo.show"
    }
  }, [_c('van-picker', {
    attrs: {
      "columns": _vm.couponSchemeInfo.listData,
      "title": _vm.couponSchemeInfo.title,
      "item-height": "1.22667rem",
      "value-key": "name",
      "show-toolbar": ""
    },
    on: {
      "cancel": function ($event) {
        _vm.couponSchemeInfo.show = false;
      },
      "confirm": _vm.handleSelectCouponScheme
    }
  })], 1), _c('van-popup', {
    attrs: {
      "get-container": _vm.getContainer,
      "position": "bottom"
    },
    model: {
      value: _vm.couponInfo.show,
      callback: function ($$v) {
        _vm.$set(_vm.couponInfo, "show", $$v);
      },
      expression: "couponInfo.show"
    }
  }, [_c('van-picker', {
    attrs: {
      "columns": _vm.couponInfo.listData,
      "title": _vm.couponInfo.title,
      "item-height": "1.22667rem",
      "show-toolbar": "",
      "value-key": "couponName"
    },
    on: {
      "cancel": function ($event) {
        _vm.couponInfo.show = false;
      },
      "confirm": _vm.handleSelectCoupon
    }
  })], 1), _c('van-popup', {
    attrs: {
      "get-container": _vm.getContainer,
      "position": "bottom"
    },
    model: {
      value: _vm.stewardsInfo.show,
      callback: function ($$v) {
        _vm.$set(_vm.stewardsInfo, "show", $$v);
      },
      expression: "stewardsInfo.show"
    }
  }, [_c('van-picker', {
    attrs: {
      "columns": _vm.stewardsInfo.listData,
      "title": _vm.stewardsInfo.title,
      "item-height": "1.22667rem",
      "show-toolbar": "",
      "value-key": "realname"
    },
    on: {
      "cancel": function ($event) {
        _vm.stewardsInfo.show = false;
      },
      "confirm": _vm.handleSelectSteward
    }
  })], 1)], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };